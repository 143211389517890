.navbar {
  padding-bottom: 1px;
  border-bottom: solid 1px #ededed;
  background-color: #ededed;
  position: sticky;
  top: 0px;
  margin-top: 18px;
  margin-bottom: 20px;
  z-index: 9;
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This ensures that .leftMenu and .rightMenu are on opposite ends */
  padding: 0 24px; /* Adjust padding as needed */
  background-color: #ededed;
  border-bottom: 0;
}

.leftMenu {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftMenu .ant-menu {
  width: 100%; /* Ensure the menu takes the full width of its parent */
  justify-content: center; /* This will work if the menu is also displayed as flex */
}

.rightMenu {
  flex: 0 0 auto; /* Prevent right menu from growing */
}

.logo {
  width: 200px;
  height: 50px;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ant-menu,
.ant-drawer-header,
.ant-drawer-content,
.layout-default.layout-signin header.ant-layout-header {
  background: #ededed;
}

.layout-default.layout-signin header.ant-layout-header {
  box-shadow: 'none' !important;
}
.navbar-menu .ant-layout-header {
  padding: 0 20px;
}

.navbar-menu .ant-menu-horizontal {
  border-bottom: 0;
}

.navbar-menu .ant-menu-item {
  padding-bottom: 0px;
}

.navbar-menu .leftMenu {
  float: left;
  flex: 1 1 auto !important;
  flex-grow: 1 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #25d366;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #6a4c93;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: 'none';
}
.navbar-menu .rightMenu {
  flex: 0 0 auto;
  min-width: 20%;
}

.menuButton {
  float: right;
  margin-top: 14px;
  display: none;
  background: none;
  margin-right: 10px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ededed;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #6a4c93;
}

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 2px solid #6a4c93;
} */

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.username {
  /* display: none; */
  margin-left: 8px;
}
@media (min-width: 1240px) {
  .nav-header {
    justify-content: space-between;
  }
}
@media (max-width: 1220px) {
  .menuButton {
    display: block;
    margin-right: 10px;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .menuButton {
    display: flex; /* Use flex display for better alignment control */
    margin-top: 14px;
    margin-right: 10px;
    position: absolute; /* Positioning the button absolutely within its relative parent */
    right: 0; /* Aligns the button to the far right */
    top: 0; /* Adjust the top as necessary to align vertically as you prefer */
  }
  .nav-header {
    position: relative; /* This makes sure the absolute positioning of menuButton is relative to nav-header */
  }

  .logo a {
    margin-left: -10px;
  }

  .logo img {
    width: 152px;
    margin-right: 10px;
  }

  .ant-drawer-title > .brand-font {
    margin-bottom: 0;
  }

  .username {
    display: inline-block;
  }
}
@media (max-width: 768px) {
  .navbar-menu .ant-menu-item {
    padding: 0 10px;
    font-size: smaller;
  }
  .logo {
    width: 150px;
  }
}
