.leaderboard {
  width: 500px;
  margin-top: -15px;
  padding: 0;
  overflow-y: auto; /* Makes the leaderboard scrollable */
  max-height: 639px; /* Set a fixed height to enforce scrolling */
  position: relative; /* Important for positioning children absolutely */
}

.leaderboard-heading {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  background: #7a7774;
  color: #ffffff;
  font-weight: bold;
  position: sticky;
  top: 0;
  border-top-left-radius: 9px; /* Rounded corners at the top */
  border-top-right-radius: 9px;
  /* z-index: 1000; */
}

.leaderboard-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  background: #fffcfc;
  color: white;
  margin-bottom: 2px;
  cursor: pointer;
  border-left: 5px solid transparent;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.leaderboard-heading span {
  text-transform: uppercase; /* Capitalize headings */
  letter-spacing: 1px; /* More spacing between letters for a sophisticated look */
}
.leaderboard-item:hover {
  background: #d8c8e9; /* Light purple on hover for better visibility */
}

.rank,
.name,
.score {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.rank {
  flex-basis: 10%;
}

.name {
  flex-basis: 80%;
}

.score {
  flex-basis: 10%;
}
