/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
@media (min-width: 992px) {
    .layout-dashboard .ant-layout-footer {
        margin: 0 0 20px 20px;
    }
    .layout-dashboard .ant-layout-sider.sider-primary {
        margin: 20px 0 0 20px;
        padding: 13px 20px;
        height: calc(100vh - 20px);
    }
    .layout-dashboard .ant-layout {
        width: auto;
        flex-shrink: 1;
        margin-left: 270px;
    }
    .layout-dashboard .header-control .sidebar-toggler {
        display: none;
    }
}

@media (min-width: 768px) {
    .layout-dashboard .ant-layout-header {
        margin: 10px 20px;
    }
    .layout-dashboard .header-control {
        margin-top: 0;
    }
    .layout-dashboard .header-control .header-search {
        margin: 0 7px;
    }
    .layout-dashboard .header-control .btn-sign-in span {
        display: inline;
    }
    .profile-nav-bg {
        margin-top: -87.8px;
    }
    .card-profile-head {
        margin: -53px 24px 24px;
    }

    .surveyFormItems {
        width: 53%;
    }
}

@media (min-width: 992px) {
    .card-billing-info.ant-card .ant-card-body {
        display: flex;
    }
    .layout-dashboard-rtl {
        overflow: auto;
    }
    .layout-dashboard-rtl .ant-layout-sider.sider-primary {
        margin: 20px 20px 0 0;
        height: calc(100vh - 20px);
    }
    .layout-dashboard-rtl .ant-layout-sider.sider-primary {
        right: 0;
        left: auto;
    }
    .layout-dashboard-rtl .ant-layout-sider.sider-primary {
        right: 0;
        left: auto;
    }
    .layout-dashboard-rtl .ant-layout {
        margin-right: 270px;
        margin-left: 0;
    }
    .layout-dashboard-rtl .ant-layout-footer {
        margin: 0 20px 20px 0;
    }
}

@media (max-width: 768px) {
    .layout-dashboard.layout-dashboard-rtl .header-control {
        justify-content: flex-end;
    }

    .aside-footer {
        padding-top: 20px;
        padding-left: 5px;
        padding-right: 7px;
    }

    .footer-box {
        background-color: #fff;
        color: #141414;
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
        margin-bottom: 8px;
    }

    .ant-tour {
        font-size: 14px;
        width: 90% !important;
        /* Other adjustments */
    }

    .surveyFormItems {
        width: 96%;
    }
    .ant-card.criclebox table th {
        /* padding-left: 8px !important; */
        padding-right: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
        white-space: normal;
    }

    .ant-table-tbody > tr > td h5 {
        font-size: 12px;
    }
}
