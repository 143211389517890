.container {
  margin: 0px 80px;
  padding: 0px 38px;
}

.containerRadius {
  border-radius: 14px;
}

.heroRow {
}

.sectionHeader {
  font-family: 'Alfa Slab One';
  color: #7a7774 !important;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 40px 0px;
  /* background: linear-gradient(145deg, #7a7774, #7a7774, #bf95e4, #f2c511); */
  /* -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.sectionHeaderInternal {
  font-family: 'Alfa Slab One';
  color: #7a7774 !important;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 40px 0px;
  background: linear-gradient(145deg, #7a7774, #7a7774);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sectionLead {
  margin-bottom: 40px;
  color: #7a7774 !important;
}

.sectionLeadInternal {
  margin-bottom: 40px;
  padding-left: 15px;
  background-color: whitesmoke;
  color: #9d89b8 !important;
  font-weight: lighter;
  border-radius: 6px;
}

.typographyBrown {
  color: #7a7774 !important;
}

.frontLogos img {
  /* width: 75%; */
  /* aspect-ratio: 3/2; */
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
  filter: grayscale(50%);
}

.heroCol {
  font-family: 'Alfa Slab One';
  font-weight: 400;
  line-height: 0.9;
  padding: 14px 0px;
}

.trust {
  font-family: 'Alfa Slab One';
  font-weight: 100;
}

.markedText {
  font-size: 28px;
  color: '#25d366' !important;
}

.heroText {
  font-size: 72px;
  color: '#6fe29a' !important;
}

.blockStyle {
  text-align: center;
  border-radius: 6px;
  background-color: whitesmoke;
  padding: 20px;
  margin: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.integrationBlockStyle {
  border-radius: 6px;
  background-color: whitesmoke;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.blogs-layout {
  margin: 0px 10px;
  background-color: #ededed;
}
.blogStyle {
  text-align: center;
  border-radius: 6px;
  background-color: whitesmoke;
  /* height: 60%; */
  padding: 4px;
  margin: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.read-more-link {
  display: block;
  margin-top: 12px;
  color: #6a4c93; /* Default color */
}

.read-more-link:hover {
  color: #bf95e4; /* Color on hover */
}

.featuresTitle {
  color: #7a7774 !important;
  background: linear-gradient(113deg, #7a7774, #6a4c93, #f2c511);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientText {
  background: linear-gradient(163deg, #6a4c93, #8e43ad, #f2c511);
  /* background: linear-gradient (124deg, #8e43ad, #2ecc70, #f2c511); */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* This is for webkit browsers */
  display: inline-block;
  /* Required to apply the gradient to text */
  font-size: 72px;
  /* Your desired font size */
  font-weight: bold;
}

.landingFredStyle {
  margin-right: 12px;
  width: 100%;
  max-height: 500px; /* Adjust based on your layout */
}
.contentStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 168px;
  color: #7a7774;
  text-align: center;
  margin-left: 17px;
  margin-bottom: 32px;
  padding: 4px 8px;
  font-weight: 300;
  border-radius: 9px;
  background: #dfdfdf;
}

.removeMargin {
  margin-left: 7px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #25d366;
}

.ant-carousel .slick-dots li button {
  background: #9d89b8;
}

.ribbon {
  background: rgb(37, 211, 102);
  font-size: 16px;
  width: 97px;
  height: 27px;
  border-radius: 3px;
  text-align: center;
  line-height: inherit;
}

/* .ant-btn-primary,
.ant-btn-default {
  color: #fff;
  background: #6a4c93;
  border: none;
  font-size: 16px;
}

.ant-btn-secondary {
  border: none;
  font-size: 16px;
} */

/* .ant-btn-primary:active {
    color: #fff;
    background: #25d366;
}

.ant-btn-primary:focus {
    background: #25d366;
}

.ant-btn-default:active {
    color: #fff;
    background: #25d366;
}

.ant-btn-default:focus {
    background: #25d366;
} */

.ant-typography mark {
  background-color: #dad2e4 !important;
  color: #7a7774 !important;
}

/* .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background-color: #25d366 !important;
    color: white;
} */

.ant-timeline-item-head {
  background-color: #dfdfdf !important;
}

.ant-timeline-item-tail {
  border-left: 2px solid #7a7774;
}

.ant-timeline-item-head-custom {
  left: 7.4px;
}

#handOnBox {
  /* animation-name: animateHand; */
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#cellphone {
  position: absolute;
  left: 0;
  bottom: 0;
  animation: 1.5s ease-in-out infinite alternate running MoveUpDown;
}

.pulse-button {
  background: linear-gradient(to right, #bf95e4, #dad2e4);
  /* Replace with your desired gradient colors */
  animation: pulse 2s ease-in-out infinite;
}

.confetti-wrapper {
  animation: fadeout 2s 8s forwards;
}

.logo-image:hover {
  filter: grayscale(0%) !important;
  opacity: 1 !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes animateHand {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(2.6px, -3px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes MoveUpDown {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(5%);
    transform: translateY(9%);
    transform: rotateY(9deg);
  }
}

@media (max-width: 992px) {
  .container {
    margin: 0px 0.5px;
    padding: 0px 8px;
  }
  .hero {
    /* margin-bottom: 40px; */
    height: 100vh;
  }

  .heroText {
    font-size: 52px;
  }

  .sectionHeader {
    font-size: 42px;
  }

  .heroRow {
    /* margin-top: 8px; */
  }

  .ant-drawer-header {
    padding: 9px 20px;
  }

  .ant-drawer-title {
    display: flex;
    justify-content: center;
  }

  .ant-drawer-header-title {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    flex-direction: row-reverse;
  }

  .ant-drawer-header-title svg {
    display: none;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .landingFredStyle {
    max-height: 150px;
  }
  .blockStyle {
    margin: 6px;
    max-width: 96%;
  }
  .heroText {
    font-size: 32px;
  }

  .markedText {
  font-size: 21px;
}
}
